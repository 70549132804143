<template>
  <span class="mud-text" v-html="result"></span>
</template>

<script>

import WebmudsText from '@webmuds/text'
import { html } from '@webmuds/colors'

export default {
  name: 'mud-text',
  props: {
    v: String,
    escape: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    result () {
      if (!this.v) return ''
      return html(WebmudsText.pad(this.v), this.escape)
    }
  }
}
</script>
